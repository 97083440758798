<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">
      <back back-url="/article"></back>
      <scroll
        ref="scroll"
        class="scroll"
        :data="data"
      >
        <div>
          <div style="display: table;width: 100%;">
            <div class="title" v-if="article.title !== ''">{{ article.title }}</div>
            <div class="visit" v-if="article.visit>0">浏览量 {{ article.visit }}</div>
            <div class="detail" v-html="article.content"></div>
          </div>
        </div>
      </scroll>
    </div>
  </transition>
</template>

<script>
import Back from '@/components/back/Back'
import Scroll from '@/components/scroll/scroll'
import { detail } from '@/api/Articles'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'

export default {
  name: 'Article',
  components: {
    Back,
    Scroll
  },
  data () {
    return {
      data: [],
      article: ''
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  mounted () {
    this._getDetail()
  },
  methods: {
    _getDetail () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      detail({
        agency_guid: this.agencyGuid,
        article_guid: this.$route.params.guid
      }).then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          this.article = data
          console.log(data)
          this.refresh()
          setTimeout(() => {
            this.$refs.scroll.refresh()
          }, 2000)
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.detail >>> strong
  font-weight bold

.detail >>> strong *
  font-weight bold

.detail >>> img
  max-width 100%
  height auto

.container
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 80px
    left 0
    right 0
    bottom 0
    overflow hidden

    .title
      margin 40px auto 0 auto
      padding 40px 20px 0 20px
      width 650px
      font-weight bold
      text-align center
      font-size 40px
      background #fff

    .visit
      margin 0 auto
      padding 10px 20px
      width 650px
      text-align center
      font-size 28px
      background #fff

    .detail
      margin 0 auto
      width 650px
      padding 40px 20px
      line-height 48px
      white-space pre-wrap
      background rgba(255, 255, 255, 1)
      overflow hidden
</style>
